import { DownloadParameter } from '../shared-modules/data-inspection/download-dialog/csv-download-model';
import { isArray, round } from 'lodash-es';
import { Parser } from '@json2csv/plainjs';
import { object as objectFormatter, string as stringFormatter } from '@json2csv/formatters';
import { editorToPlainText } from './slate-richtext/slate.util';

const objectFormatterFunction = objectFormatter();
const excelQuote = '"';
const excelEscapedQuote = '""""';
/*
 * CSV Injection Regex (see also https://owasp.org/www-community/attacks/CSV_Injection)
 * Detect Equals(=), Plus(+), Minus(-), At(@), Tab, Carriage return, Comma (,) and Semicolon (;).
 * Some examples for String values which are recognized by the regex:
 * "=123+1", "\t123+1", "@word", ",=1+2", ";=1+2"
 * Some examples which are conscious NOT recognized by the regex:
 * "-12","+0.2", "Not@Recognized"
 */
// eslint-disable-next-line no-control-regex
const csvInjectionRegex = new RegExp('^[=@;,\x00-\x1F\x7F]|[+-](?![0-9][0-9/,. -]*$)', 'gm');

export function generateCsvUsingOptions(options, data) {
  try {
    const parser = new Parser(options);
    return parser.parse(data);
  } catch (error) {
    console.log('CSV content generation failed with an error. Empty content is returned.', error);
    return null;
  }
}

export function calculateFieldsTableEntryCellsFromColumnTitles(columns: string[]) {
  const fields = [];
  for (const [index, column] of columns.entries()) {
    fields.push({ label: column, value: `${index}.value` });
  }
  return fields;
}

export function json2CsvOptionsFromDownloadParameterOptions(
  downloadParameter: DownloadParameter
): any {
  const considerAsExcel = downloadParameter.label.toLocaleLowerCase().includes('excel');
  const decimalSeparator = resolveSeparator(downloadParameter.decimalSeparator);
  const quote = downloadParameter.masking ? '"' : '';
  return {
    delimiter: resolveSeparator(downloadParameter.columnSeparator),
    eol: resolveEol(downloadParameter.lineBreak),
    quote: quote,
    withBOM: considerAsExcel,
    header: downloadParameter.header === 'present',
    formatters: {
      string: considerAsExcel
        ? (stringVal) => formatExcelString(stringVal, quote)
        : stringFormatter(),
      number: (numberVal) => formatNumber(numberVal, decimalSeparator, quote),
      object: (objectVal) => formatObject(objectVal, quote)
    }
  };
}

export function resolveSeparator(columnSeparator: string) {
  switch (columnSeparator) {
    case 'tab':
      return '\t';
    case 'semicolon':
      return ';';
    case 'dot':
      return '.';
    default:
      return ',';
  }
}

function resolveEol(eol: string) {
  switch (eol) {
    case 'CRLF':
      return '\r\n';
    case 'LFCR':
      return '\n\r';
    case 'LF':
      return '\n';
    default:
      return '\r';
  }
}

function formatNumber(value, separator: string, quote: string) {
  if (value === undefined || value === null) {
    return '';
  }
  const decimal = round(value, 50).toString();
  return `${quote}${replaceSeparator(decimal, separator)}${quote}`;
}

function replaceSeparator(value, separator) {
  return value.replace('.', separator);
}

function formatObject(objectVal, quote: string) {
  if (isArray(objectVal)) {
    const concatenatedArray = objectVal
      .map((arrValue) => (arrValue.value ? arrValue.value : arrValue))
      .join(',');
    return `${quote}[${concatenatedArray}]${quote}`;
  } else if (objectVal?.editor) {
    // special conversion for slate editor, otherwise it would cause a cycle reference problem
    return editorToPlainText(objectVal?.editor);
  }
  return objectFormatterFunction(objectVal);
}

function formatExcelString(value, quote: string) {
  if (csvInjectionRegex.test(value)) {
    return `"=""${value.replace(new RegExp(excelQuote, 'g'), excelEscapedQuote)}"""`;
  }
  return `${quote}${value}${quote}`;
}
